import { Footer, Header } from '@digital-retail/falabella-ui-cross-components';
import { isStandalone } from '@digital-retail/store-manager';
import Head from 'next/head';
import Router from 'next/router';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import constants from '../../config/constants';
import _ from '../../utils/LodashImports';
import { navigateWithUrl, redirect } from '../../utils/RouteHandler';
import UserContext from '../../utils/UserContext';
import WebStorage from '../../utils/WebStorage';
import WithUrl from '../../utils/WithUrl';
import { layoutStyle } from './Layout.style';

const LayoutContainer = ({ children, appCtx }) => {
  const layoutCss = layoutStyle(appCtx.tenant);
  const [cartCount, setCartCount] = useState('');
  const { refreshUserData } = useContext(UserContext.Context);

  useEffect(() => {
    if (appCtx.USER_DATA_COOKIE) {
      const userDetails = appCtx.USER_DATA_COOKIE.split('*');
      setCartCount(userDetails[1]);
    }
  }, [appCtx.USER_DATA_COOKIE]);

  const currentLocation = _.get(appCtx, 'zoneName', '');

  const catalystBaseUrl = _.get(appCtx, 'siteConfig.endpoints.CATALYST_AUTH_BASEURL', 'https://www.falabella.com');

  const handleRouteChange = (termo, searchInSis) => {
    const SEARCH_PAGE = isStandalone({ store: appCtx.store }) ? 'SA_SEARCH_PAGE' : 'SEARCH_PAGE';
    if ((appCtx.store && searchInSis) || !appCtx.store) {
      if (!['/search', '/buscar'].includes(Router.router.route)) {
        if (!searchInSis) {
          const url = WithUrl(SEARCH_PAGE, appCtx.regionCode, {
            custom: {
              searchTerm: termo,
            },
          });
          redirect(url);
        } else {
          const url = WithUrl(
            SEARCH_PAGE,
            appCtx.regionCode,
            {
              custom: {
                searchTerm: termo,
              },
            },
            appCtx.store
          );
          redirect(url);
        }
      } else {
        navigateWithUrl({
          site: appCtx.tenant,
          url: `Ntt=${termo}`,
          store: appCtx.store,
          regionCode: appCtx.regionCode,
          onRouteChange: () => {
            WebStorage.setItem({
              name: constants.SESSION_STORAGE_NEW_PAGE,
              shouldPersist: false,
              value: 'true',
            });
            appCtx.setBusy(true);
          },
        });
      }
    } else {
      const url = WithUrl(SEARCH_PAGE, appCtx.regionCode, {
        custom: {
          searchTerm: termo,
        },
      });
      redirect(url);
    }
  };

  const onUserLogin = () => {
    refreshUserData();
  };

  return (
    <>
      <Head>
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-Black.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-BlackItalic.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-Bold.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-BoldItalic.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-Hairline.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-HairlineItalic.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-Italic.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-Light.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-LightItalic.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/fonts/Lato-Regular.ttf"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/icons/fonts/sodimac.ttf?fu3jcb"
          as="font"
          type="font/ttf"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/a/fa/listing/static/icons/fonts/sodimac.woff?fu3jcb"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
      </Head>
      <Header
        currentLocation={currentLocation}
        data={{
          header: {
            ..._.get(appCtx, 'header'),
            transversalBannerHtml: _.get(appCtx, 'transversalBanner'),
          },
          sisNavigationMenu: _.get(appCtx, 'sisNavigationMenu'),
          taxonomy: _.get(appCtx, 'taxonomy'),
        }}
        isGeoLocationEnabled={_.get(appCtx, 'siteConfig.toggles.isGeoLocationEnabled', false)}
        isTypeAheadEnabled={_.get(appCtx, 'siteConfig.toggles.isTypeAheadEnabled', false)}
        isATGLoginEnabled={_.get(appCtx, 'siteConfig.toggles.isATGLoginEnabled', false)}
        isDefaultComuna={_.get(appCtx, 'isDefaultComuna', false)}
        selectedZone={_.get(appCtx, 'comuna.data', undefined)}
        onUserLogout={appCtx.logoutUser}
        onUserLogin={onUserLogin}
        enableAnalyticsInfoCall
        isZoneModalEnabled
        userCartCount={cartCount}
        parentRouter={Router}
        handleRouteChange={handleRouteChange}
        catalystBaseUrl={catalystBaseUrl}
        store={appCtx.store}
        handleSoftRefresh={appCtx.reloadOnZoneChange}
        isPopOverVisible={false}
        isWebPushNotificationEnabled={_.get(appCtx, 'siteConfig.toggles.isWebPushNotificationEnabled', false)}
        isOrangeHeader={appCtx.isOrangeHeader}
        isSISLevel2Header={appCtx.isSISLevel2Header}
        isFingerprintTrackingEnabled={_.get(appCtx, 'siteConfig.toggles.isFingerprintTrackingEnabled', true)}
        isGoogleTagManagerEnabled
      />
      <div className="main">{children}</div>
      {appCtx.busy > 0 && <Loader />}
      <Footer data={_.get(appCtx, 'footer')} />
      <style jsx>{layoutCss}</style>
    </>
  );
};

LayoutContainer.propTypes = {
  children: PropTypes.node,
  appCtx: PropTypes.object.isRequired,
};

LayoutContainer.defaultProps = {
  children: null,
};

export default LayoutContainer;
